<template>
  <div>
    <div class="start-image">
      <v-img
        position="center bottom"
        contain
        v-show="step === 2"
        :src="require(`@/assets/illustrations/australia.svg`)"
      ></v-img>
      <v-img
        position="center bottom"
        contain
        v-show="step === 1"
        :src="require(`@/assets/illustrations/adult.svg`)"
      ></v-img>
      <v-img
        position="center bottom"
        contain
        v-show="step === 3"
        :src="require(`@/assets/illustrations/partner.svg`)"
      ></v-img>
      <v-img
        position="center bottom"
        contain
        v-show="step === 4"
        :src="require(`@/assets/illustrations/children.svg`)"
      ></v-img>
      <v-img
        position="center bottom"
        contain
        v-show="step === 5"
        :src="require(`@/assets/illustrations/pets.svg`)"
      ></v-img>
      <v-img
        position="center bottom"
        contain
        v-show="step === 6"
        :src="require(`@/assets/illustrations/house.png`)"
      ></v-img>
    </div>
    <v-slider
      :value="step"
      :min="0"
      :max="5"
      :readonly="true"
      class="start-slider"
      color="orange"
      track-color="#000000"
    ></v-slider>
    <div class="text-center">Question {{ step }} of 5</div>
  </div>
</template>

<script>
export default {
  name: "RegistrationSlider",
  components: {},
  props: {
    step: {
      default: 1,
      type: Number,
    },
  },
  data: function () {
    return {
      imgs: [
        "australia.png",
        "adult.png",
        "partner.png",
        "children.png",
        "pets.png",
        "house.png",
      ],
    }
  },
}
</script>

<style lang="scss">
.start-image {
  margin: auto;
  width: 461px;
  height: 247px;
  display: flex;
  align-items: flex-end;

  .v-image {
    width: auto;
    height: 100%;
    display: block;
    margin: auto;
  }

  @media (max-width: 525px) {
    width: auto;
    max-width: 100%;
    height: 145px;
  }
}

.start-slider {
  .v-slider--horizontal {
    min-height: 0;

    .v-slider__track-container {
      height: 4px;
      .v-slider__track-background {
        border-radius: 4px;
      }
      .v-slider__track-fill {
        border-radius: 4px;
      }
    }
  }
}
</style>
