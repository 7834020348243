<template>
  <div class="text-left">
    <div>
      <div class="text-label">Email Address</div>
      <v-text-field
        outlined
        v-model="local.email"
        type="email"
        @blur="showErrors.email = true"
        @focus="showErrors.email = false"
        :error-messages="
          showErrors.email && errorMessages.email ? errorMessages.email : null
        "
      ></v-text-field>
    </div>
    <div>
      <div class="text-label">Password</div>
      <v-text-field
        outlined
        v-model="local.password"
        type="password"
        @blur="showErrors.password = true"
        @focus="showErrors.password = false"
        :error-messages="
          showErrors.password && errorMessages.password
            ? errorMessages.password
            : null
        "
      ></v-text-field>
    </div>
    <div class="mb-4">
      <div class="text-label">Confirm Password</div>
      <v-text-field
        outlined
        v-model="local.confirmPassword"
        type="password"
        @blur="showErrors.confirmPassword = true"
        @focus="showErrors.confirmPassword = false"
        :error-messages="
          showErrors.confirmPassword && errorMessages.confirmPassword
            ? errorMessages.confirmPassword
            : null
        "
      ></v-text-field>
    </div>
    <div class="mb-4">
      <v-checkbox
        v-model="local.accept"
        class="mb-4"
        :error-messages="
          showErrors.accept && errorMessages.accept
            ? errorMessages.accept
            : null
        "
      >
        <template slot="label">
          <span class="black--text"
            >I have read, understood and accept the
            <a
              @click.stop
              href="https://www.willed.com.au/privacy-policy"
              target="_blank"
              >Privacy Policy</a
            >
            and
            <a
              @click.stop
              href="https://www.willed.com.au/platform-terms-and-conditions"
              target="_blank"
              >Willed Platform Terms and Conditions</a
            ></span
          >
        </template>
      </v-checkbox>
    </div>
    <div class="text-center">
      <v-btn
        v-bind="largeButton"
        @click="register"
        x-large
        :loading="processing"
      >
        Register
      </v-btn>
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash"
export default {
  name: "RegistrationForm",
  data: function () {
    return {
      local: {
        email: "",
        password: "",
        confirmPassword: "",
        accept: false,
      },
      valid: true,
      processing: false,
      showErrors: {
        email: false,
        password: false,
        confirmPassword: false,
        accept: false,
      },
    }
  },
  computed: {
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        email: null,
        password: null,
        confirmPassword: null,
        accept: null,
      }

      if (this.local.email === "") {
        msgs.email = "Required field"
      }

      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!msgs.email && !pattern.test(this.localFormatted.email)) {
        msgs.email = "Not a valid email address"
      }

      if (this.local.password === "") {
        msgs.password = "Required field"
      }

      msgs.password = this.validatePassword(this.localFormatted.password)

      if (
        !isEqual(
          this.localFormatted.password,
          this.localFormatted.confirmPassword
        )
      ) {
        msgs.confirmPassword = "Please confirm your password"
      }

      if (!this.local.accept) {
        msgs.accept = "Accept to proceed"
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    localFormatted() {
      return {
        email: this.local.email.trim(),
        password: this.local.password,
        confirmPassword: this.local.confirmPassword,
        accept: this.local.accept || false,
      }
    },
  },
  methods: {
    validatePassword(v) {
      if (!v) return "Password is required"

      if (v.length < 8) return "Password must be at least 8 characters long"

      if (/.*[a-z].*/.test(v) === false)
        return "Password must contain at least 1 lowercase letter"

      if (/.*[A-Z].*/.test(v) === false)
        return "Password must contain at least 1 uppercase letter"

      if (/.*[0-9].*/.test(v) === false)
        return "Password must contain at least 1 number"

      if (/.*[$#?!@$%^&*-].*/.test(v) === false)
        return "Password must contain at least 1 special character : # ? ! @ $ % ^ & * -"

      return null
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    async register() {
      this.showErrorsSwitch()

      if (this.isValid) {
        this.processing = true
        await this.$store
          .dispatch("account/auth_register", {
            email: this.localFormatted.email,
            password: this.localFormatted.password,
          })
          .then((res) => {
            this.$store.commit("app/SET_DATA_LOADED", false)

            // New referral
            const referralData = this.$store.getters["anon/getReferralTracking"]

            if (referralData) {
              this.$store.commit("account/SET_REGISTRATION_DATA", {
                key: "referrer",
                value: referralData,
              })
            }

            // Save collected data
            const userData = this.$store.getters["anon/getAnonData"]

            if (Object.keys(userData).length > 0) {
              this.$store.commit("account/SET_REGISTRATION_DATA", {
                key: "userData",
                value: userData,
              })
            }

            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: "userRegistered",
              userId: res.user.uid,
              userEmail: res.user.email,
            })

            return this.$store.dispatch(
              "account/auth_initialise_account",
              res.user.uid
            )
          })
          .then(() => {
            return this.$router.push({ path: "/" })
          })
          .catch((err) => {
            this.$store.commit("app/SET_MESSAGE", {
              text: err.message,
              type: "error",
            })
            this.processing = false
          })
      }
    },
  },
}
</script>
