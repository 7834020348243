<template>
  <div>
    <v-window
      v-model="window"
      touchless
    >
      <v-window-item value="welcome">
        <div class="pa-6">
          <registration-welcome @changeWindow="window = 'questions'" />
        </div>
      </v-window-item>

      <v-window-item
        value="questions"
        class="text-center"
      >
        <div class="pa-6">
          <registration-slider :step="step" />
          <div v-if="step === 2">
            <div class="w-title mb-8">Which state do you reside?</div>
            <div class="btn-options-grid">
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'act')
                  step++
                "
                >ACT</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'nsw')
                  step++
                "
                >NSW</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'nt')
                  step++
                "
                >NT</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'qld')
                  step++
                "
                >QLD</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'sa')
                  step++
                "
                >SA</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'tas')
                  step++
                "
                >TAS</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'vic')
                  step++
                "
                >VIC</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('state', 'wa')
                  step++
                "
                >WA</v-btn
              >
              <v-btn
                v-bind="outlineButton"
                @click="window = 'error'"
                >None</v-btn
              >
            </div>
          </div>
          <div v-if="step === 1">
            <div class="w-title">Are you over 18 years of age?</div>
            <div class="btn-options">
              <v-btn
                v-bind="blueButton"
                @click="step++"
                >Yes</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="window = 'error'"
                >No</v-btn
              >
            </div>
          </div>
          <div v-if="step === 3">
            <div class="w-title">What is your relationship status?</div>
            <div class="btn-options wide">
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('partner', 'single')
                  step++
                "
                >Single</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('partner', 'married')
                  step++
                "
                >Married</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('partner', 'defacto')
                  step++
                "
                >Defacto</v-btn
              >
            </div>
          </div>
          <div v-if="step === 4">
            <div class="w-title">
              Do you have any children under 18 years old?
            </div>
            <div class="btn-options">
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('children', 'yes')
                  step++
                "
                >Yes</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('children', 'no')
                  step++
                "
                >No</v-btn
              >
            </div>
          </div>
          <div v-if="step === 5">
            <div class="w-title">Do you have any pets?</div>
            <div class="btn-options">
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('pets', 'yes')
                  window = 'hooray'
                "
                >Yes</v-btn
              >
              <v-btn
                v-bind="blueButton"
                @click="
                  setRegistrationData('pets', 'no')
                  window = 'hooray'
                "
                >No</v-btn
              >
            </div>
          </div>

          <div class="text-left pt-12">
            <a
              @click="stepBack"
              class="grey--text"
              ><v-icon size="18">mdi-chevron-left</v-icon>Back</a
            >
          </div>
        </div>
      </v-window-item>

      <v-window-item value="hooray">
        <div class="pa-6">
          <div class="text-center auth-form-inner">
            <div>
              <div class="w-page-title mb-4 font-cooper font-weight-bold">
                Hooray!
              </div>
              <div class="w-subtitle mb-8">
                You are ready to create your will. It is as easy as:
              </div>
            </div>
            <registration-hooray />
            <v-btn
              v-bind="largeButton"
              @click="window = 'create'"
              class="mt-4"
              >Create account</v-btn
            >
          </div>
        </div>
        <div class="divider"></div>
        <div class="pa-4 bg-grey-10 text-center text-sm">
          Got questions? Contact us on
          <a href="tel:1300945533">1300 945 533</a>,
          <a href="mailto:hello@willed.com.au">hello@willed.com.au</a> or via
          the live chat available on every page.
        </div>
      </v-window-item>

      <v-window-item value="create">
        <v-alert
          text
          color="blue"
          v-if="$route.query.ptok"
          class="mb-0"
          style="
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          "
        >
          <div class="black--text">
            <div class="w-label mb-2">Partner Account</div>
            <div class="mb-2 body-2">
              Your partner has invited you to create an account through the
              Willed online platform.
            </div>
            <div class="mb-2 body-2">
              Create an account below to get started. If your partner has
              already made a purchase for you, it will be added to your account
              at checkout at no cost.
            </div>
            <div class="mb-2 body-2">
              If you have already created your own account, you can click the
              login link at the bottom of the page.
            </div>
          </div>
        </v-alert>
        <div class="pa-6">
          <div class="text-center auth-form-inner">
            <div class="w-title font-weight-bold font-cooper mb-8">
              Create account
            </div>
            <registration-form />
            <p class="mt-6">
              Already have an account?
              <a @click="$router.push('/login')">Log in</a>
            </p>
          </div>
        </div>
      </v-window-item>

      <v-window-item value="error">
        <div class="pa-6">
          <div class="text-center max-w-sm">
            <div class="error-image mb-8">
              <img src="@/assets/illustrations/sorry.svg" />
            </div>
            <p>
              Unfortunately, we can only create wills for adults over the age of
              18 who are living in Australia.
            </p>
            <div class="text-center mt-8">
              <a
                class="sorry-back"
                @click="window = 'questions'"
                ><v-icon color="black">mdi-chevron-left</v-icon>Back</a
              >
            </div>
          </div>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import RegistrationForm from "./forms/Registration"
import RegistrationHooray from "./slides/Hooray"
import RegistrationSlider from "./slides/Slider"
import RegistrationWelcome from "./slides/Welcome"

export default {
  name: "Registration",
  components: {
    RegistrationHooray,
    RegistrationForm,
    RegistrationSlider,
    RegistrationWelcome,
  },

  mounted() {
    if (this.$route.query.ptok) {
      this.window = "create"
      this.$store.commit("anon/SET_PARTNER_TOKEN", this.$route.query.ptok)
    } else if (this.$route.path === "/register") {
      this.window = "hooray"
    }
  },
  data: function () {
    return {
      window: "start",
      step: 1,
      blueButton: {
        depressed: true,
        color: "primary",
        class: "text-capitalize display-1 px-7 black--text",
        height: 48,
      },
    }
  },
  methods: {
    setRegistrationData(key, value) {
      this.$store.commit("account/SET_REGISTRATION_DATA", {
        key: key,
        value: value,
      })
      this.$store.commit("anon/SET_ANON_DATA", {
        key: key,
        value: value,
      })
    },
    stepBack() {
      if (this.step === 1) {
        this.window = "started"
      } else if (this.step > 1) {
        this.step--
      }
    },
  },
}
</script>

<style lang="scss">
.welcome-image {
  margin: auto;
  max-width: 463px;
  height: 200px;
  display: flex;
  align-items: flex-end;

  @media (max-width: 525px) {
    height: 140px;
  }
  @media (max-width: 460px) {
    height: 120px;
  }

  img {
    width: auto;
    height: 100%;
    display: block;
    margin: auto;
  }
}

.error-image {
  margin: auto;
  width: 290px;
  height: 212px;
  display: flex;
  align-items: flex-end;

  img {
    width: auto;
    height: 100%;
    display: block;
    margin: auto;
  }
}

.btn-options-grid {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr 1fr;
}
.btn-options {
  display: flex;
  width: 338px;
  margin: 48px auto 0;
  justify-content: space-between;
  &.dense {
    margin: 16px auto 0;
  }
  &.wide {
    width: 520px;
  }
  .v-btn {
    width: 158px;
  }

  @media (max-width: 525px) {
    align-items: center;
    width: auto;
    max-width: 100%;
    padding: 0 16px;
    flex-direction: column;
    .v-btn {
      margin-bottom: 16px;
    }
  }
}

a.sorry-back {
  color: #000 !important;
  font-weight: 500;
  font-size: 24px;
  i.v-icon.v-icon {
    vertical-align: baseline;
  }
}

a i.v-icon.v-icon {
  vertical-align: baseline;
}
</style>
