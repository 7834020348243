<template>
  <div class="text-center max-w-sm">
    <div class="welcome-image">
      <img src="@/assets/illustrations/welcome.svg" />
    </div>
    <div class="w-title py-8 font-cooper font-weight-bold">Welcome to Willed</div>
    <p>Get your legally valid Will in as little as 15 minutes.</p>
    <p>
      Our Will experts are available by phone, email and live chat to assist
      you.
    </p>
    <p>To get started we just have a few questions...</p>
    <p>
      <v-btn v-bind="largeButton" @click="$emit('changeWindow')" class="mt-4"
        >Get started</v-btn
      >
    </p>
    <book-call />
    <p class="mt-6">
      Already have an account?
      <a @click="$router.push('/login')">Log in</a>
    </p>
  </div>
</template>

<script>
import BookCall from '../components/BookCall'

export default {
  name: 'RegistrationWelcome',
  components: {
    BookCall
  }
}
</script>
